<template>
  <div ref="app">
    <div>
      <div class="loader-wrapper" :class="{'is-active': !showApp }">
        <div class="loader is-loading"></div>
      </div>
    </div>

    <div :style="{visibility: showApp ? 'visible' : 'hidden'}">
      <LandingComponent
        @mounted="landingReady=true; checkAppAvailability();"
        :active="active_page==0"

        title="Hafıza Kapısı" 
        content="<p>Medeniyetlerin Hafıza Kapısı, Anadolu'nun ve Türkiye'nin paha biçilmez maddi ve manevi mirasının korunması ve sergilenmesi amacıyla hazırlanmış bir arşiv projesidir. Günümüz dünyasında kültürel mirasımız doğal afetler, iklim değişikliği ve çatışmalar ile her zaman olduğundan daha büyük tehdit altındadır. UNDP Türkiye, Koç Üniversitesi KARMA XR Laboratuvarı, ve META'nın öncülük ettiği bu proje, yerel mirasları titizlikle arşivlemek ve görsel temsiller oluşturmak için kitle kaynaklı bir platform olmanın gücünden ve fotogrametri, 3D modelleme ve AR filtreleri dahil olmak üzere en ileri dijitalleştirme tekniklerinden yararlanıyor.</p>
<p>Projenin başlangıcı Şubat 2023'te Türkiye'de yaşanan depremle aynı zamana denk geliyor. Proje 2023 yılında üç farklı miras yapısı ile başladı. Her biri depremden doğrudan etkilenen şehirlerden seçilmiş olan Gaziantep Kalesi, Maraş Kapalıçarşı ve Hatay Arkeoloji Müzesi'ndeki Şuppililuima Heykeli.&nbsp;</p>"  
        @onContributeLandmark = "is_contributing=true; contributionType=1;"
        @onContributePerson = "is_contributing=true; contributionType=0;"
        @onArrowClicked="go_to_page(1)"
      />
      <MapComponent ref="map"
        @mounted="mapReady=true; checkAppAvailability();"
        @resized="mapResized=true; checkAppAvailability();"
        :active="active_page==1"

        @onArrowClicked="go_to_page(0)"
        @onCreateCityMemoryClicked="is_contributing=true; contributionType=1;"
        @onCreatePersonMemoryClicked="is_contributing=true; contributionType=0;"
      />

      <Transition name="contribution-transition">
        <ContributionComponent @success="onContributionSuccess" @onClose="is_contributing = false" v-if="is_contributing" :contribution_type="contributionType"/>
      </Transition>
    </div>
  </div>

</template>

<script>

import LandingComponent from '@/components/LandingComponent.vue'
import MapComponent from '@/components/MapComponent.vue'
import ContributionComponent from '@/components/ContributionComponent.vue'
import zenscroll from 'zenscroll'

export default {
  name: 'App',
  data() {
    return{
      contributionType: -1,
      showApp: false,
      landingReady: false,
      mapReady: false,
      mapResized: false,

      active_page: 0,
      num_pages: 2,
      is_contributing: false
    }
  },
  methods: {
      onContributionSuccess() {
        this.showApp = false;
        this.is_contributing = false;
        // this.$refs.map.reload(),
        setTimeout(() => {
          this.showApp = true;
        }, 500);
      },
      checkAppAvailability() {
        if(this.landingReady && this.mapReady && this.mapResized){
          this.landingReady = false;
          this.mapReady = false;
          this.mapResized = false;
          
          zenscroll.to(this.landing_component, 500);
          this.showApp = true;
        }
      },
      go_to_page(number){
          if(number < 0 || number > this.num_pages)
              return;
          this.active_page = number;

          this.scroll(number);
      },
      scroll(number){
          if(number == 0){
            zenscroll.to(this.landing_component, 500);
          }
          else {
            zenscroll.to(this.map_component, 500);
          }
      },
      resizeHandler(){
        if(this.active_page == 0){
            zenscroll.to(this.landing_component, 0);
          }
          else {
            zenscroll.to(this.map_component, 0);
          }
      }
  },
  components: {
    LandingComponent,
    MapComponent,
    ContributionComponent,
  },
  mounted() {
    this.app_element = this.$refs.app;
    this.landing_component = this.app_element.querySelector('#landing-component');
    this.map_component = this.app_element.querySelector('#map-component');
    
    document.documentElement.style.overflow = "hidden";

    this.landing_component.style.overflow = "auto";
    this.map_component.style.overflow = "auto";

    window.addEventListener("resize", this.resizeHandler);

    // var handleScroll = (e) => {
    //     console.log(e)
    //     this.go_to_page((this.active_page + 1) % this.num_pages);
    // };
    // this.app_element.addEventListener('scroll', handleScroll, false);

    // var handleEvent = function(e) {
    //     e.preventDefault();      // disables scrolling by mouse wheel and touch move
    // };  
    // this.app_element.addEventListener('scroll', handleEvent, false);
    // this.app_element.addEventListener('mousewheel', handleEvent, false);
    // this.app_element.addEventListener('touchmove', handleEvent, false);
  },
  unmounted()
  {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
</script>

<style lang="scss">
#app {
  font-family: $d-font-family !important;
  color: $d-primary-text-color !important;
  // min-width: 100px;
  // overflow: hidden;
}

body, .component { scroll-behavior: smooth }

.component p, h1, h2, a {
    color: $d-primary-text-color !important;
}

.contribution-transition-enter-active,
.contribution-transition-leave-active {
  transition: transform 0.5s ease, opacity 0.6s ease-out;
}

.contribution-transition-enter-from,
.contribution-transition-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #282828;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  pointer-events: none;
  z-index: 10;

  .loader {
    height: 80px;
    width: 80px;
  }
  &.is-active {
    opacity: 1;
    // z-index: 1;
  }
}

</style>