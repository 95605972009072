<template class="component">
    <div class="column custom
    is-one-quarter-desktop
    is-full-mobile 
    ">
        <section class="main-content">
            <div class="rows">
                <div class="row">
                    <div class="columns is-mobile">
                        <div class="column is-three-quarters-mobile pl-0">
                            <div class="is-uppercase" style="font-weight: 800;">
                                Hafıza arama
                            </div>
                        </div>
                        <div class="column is-one-quarter-mobile">
                            <a @click="$emit('onClose')" class="is-pulled-right">
                                <img src="@/assets/images/close.svg"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="columns">
                        <div class="column is-full pl-0 pr-0">
                            <div class="field">
                                <div id="search-bar" class="control has-icons-right is-large">
                                    <input v-model="searchbar" class="input" type="text" placeholder="Aramak istediğiniz bellek kaydı" />
                                        <!-- <span class="icon is-right">
                                            <font-awesome-icon icon="fa-regular fa-map" />
                                        </span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tabs-container" class="row mt-4 mb-8">
                    <div class="columns is-mobile is-centered">
                        <div class="column is-two-thirds">
                            <div id="tabs" class="columns is-mobile is-centered">
                                <a class="column">
                                    Tümü
                                </a>
                                <a class="column">
                                    Kişiler
                                </a>
                                <a class="column">
                                    Mekanlar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="rows">
                        <a class="row search-result-row" v-for="entity in filteredList" :key="entity" @click="onEntityClick(entity)" @mouseover="$emit('onMouseOverEntity', entity)">
                            <div class="columns is-mobile is-vcentered">
                                <div class="column is-one-fifth image-container">
                                    <figure class="image is-1by1">
                                        <!-- <img src="@/assets/images/test_person_img.png" alt="" class="is-rounded"> -->
                                        <img :src="`https://hafizakapisi.com.tr/media/image/thumb.php?id=${entity.imgId}`" alt="" class="is-rounded">
                                    </figure>
                                </div>
                                <div class="column">
                                    <h2>{{ entity.title }}</h2>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>

export default {
    props: {
        all_data: null,
    },
    emits:['onMouseOverEntity'],
    data() {
        return {
            searchbar: '',
        }
    },
    setup() {
        
    },
    methods: {
        onEntityClick(entity){
            this.$emit('onEntityClick', entity);
        }
    },
    computed: {
        filteredList() {
            console.log(this.all_data)
            return this.all_data.filter(data => {
                return data.title.toLowerCase().includes(this.searchbar.toLowerCase())
            })
        }
    },
    mounted() {
    },
    components: {
        
    }
}
</script>

<style lang="scss" scoped>
    .container {
        @include from($desktop) {
            // max-height: -0.75rem;
            overflow-y: auto;
        }
    }

    .main-content{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-content > .rows {
        width: 100%;
        padding: 32px 50px 0px;
    }

    #tabs-container {
        margin-bottom: 3rem;
    }

    #tabs {
        font-size: 0.75rem;

        .column {
            padding-top: 0;
            text-transform: uppercase;
        }
    }

    .search-result-row{
        padding: 1rem 0;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        flex-direction: column;

        h2 {
            font-weight: 700;

            @include until($desktop){
                font-size: 1rem;
            }
            @include from($desktop){
                font-size: 1.125rem;
            }
        }

        .image-container{
            padding: 0;
            @include until($desktop){
                max-width: 2rem;
                max-height: 2rem;
            }
            @include from($desktop){
                max-width: 2.5rem;
                max-height: 2.5rem;                
            }

        }
		img
		{
			object-fit: cover;
		}
    }

</style>