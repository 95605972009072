/* eslint-disable no-unused-vars */

export default class EntityLocation {
    coordinates = null;
    address_string = "";

    constructor({ coordinates = null, address_string = "" })
    {
        if (!coordinates && !address_string) {
            console.error("No location data provided!");
            return;     // undefined location
        }

        this.coordinates = coordinates;
        this.address_string = address_string;
    }
}

/* eslint-enable no-unused-vars */
