/* eslint-disable no-unused-vars */

import BaseEntity from '@/models/BaseEntity.js'

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

export default class PersonEntity extends BaseEntity {
    constructor({id, fullname, birth_date, death_date, location, imgId}){
        super({id, location});

        // this.first_name = first_name;
        // this.last_name = last_name;
        this.fullname = fullname;
        this.birth_date = birth_date;
        this.death_date = death_date;
        this.imgId = imgId;
    }
    
    getFullName() {
        return `${this.fullname}`;
        // return `${this.first_name} ${this.last_name}`;
    }
    
    getBirthDate() {
        if(!this.birth_date){
            return null;
        }
        const dmy = this.toDMY(this.birth_date);
        return `${dmy.day.pad(2)}.${dmy.month.pad(2)}.${dmy.year}`;
    }

    getDeathDate() {
        if(!this.death_date){
            return null;
        }
        const dmy = this.toDMY(this.death_date);
        return `${dmy.day.pad(2)}.${dmy.month.pad(2)}.${dmy.year}`;
    }
}

/* eslint-enable no-unused-vars */