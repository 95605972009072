<template class="component">
    <div class="column custom
    is-half-desktop is-offset-half-desktop
    is-full-mobile 
    ">
        <section class="section main-content">
            <div class="rows">
                <div class="row">
                    <div class="columns is-mobile">
                        <div class="column is-one-third-mobile is-one-quarter-tablet">
                            <figure class="image is-1by1">
                                <!-- <img src="@/assets/images/test_person_img.png" alt="" class="is-rounded"> -->
                                <img :src="`https://hafizakapisi.com.tr/media/image/thumb.php?id=${current_data.imgId}`" alt="" class="is-rounded">
                            </figure>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="columns is-mobile">
                        <div class="column is-full">
                            <h1 class="title is-size-3-mobile is-size-2-tablet">
                                {{current_data.title}}
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="columns">
                        <div class="column is-one-third-tablet">
                            <h1 class="entry-date subtitle is-size-6-mobile is-size-6-tablet">
                                {{current_data.date}}
                            </h1>
                        </div>
                        <div class="column">
                            <h1 class="entry-address subtitle is-size-6-mobile is-size-6-tablet">
                                {{current_data.address}}
                            </h1>
                        </div>
                    </div>
                </div>

                <!-- <MemoryComponent
                    :fullname="`${primaryMemory.ownerName}`"
                    :content="primaryMemory.info" 
                    :relation="primaryMemory.relationship"
                    :gallery="primaryMemory.gallery"
                    :timeCreated="primaryMemory.timeCreated"
                />


                <div v-if="all_photos.length">
                    <div class="row horizontal-divider short"></div>
                    <div class="row">
                        <div class="columns">
                            <div class="column">
                                <p class="c2 is-uppercase has-text-weight-semibold">Fotoğraf Albümü</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-4">
                        <div id="photo-library" class="columns is-mobile is-variable is-multiline is-2">
                            <div v-for="photo in all_photos" :key="photo" class="column is-half-mobile is-2">
                                <div>
                                    <figure class="image is-1by1">
                                        <img :src="`https://hafizakapisi.com.tr/media/image/thumb.php?id=${photo}`" alt="" class="is-square">
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row horizontal-divider"></div>
                </div> -->

                <!-- Contribute Button -->
                <!-- <div class="columns">
                    <div class="column is-one-third-desktop">
                        <button class="button primary is-uppercase">
                            <p>Katkı yap</p>
                        </button>
                    </div>
                </div> -->
                <!-- <div class="row horizontal-divider" style="border-color: #4B9099;"></div> -->
                

                <!-- Rest of the memories -->
                <div v-for="memory in memory_data" :key="memory">
                    <MemoryComponent 
                        :fullname="memory.ownerName" 
                        :content="memory.info" 
                        :relation="memory.relationship"
                        :gallery="memory.gallery"
                        :timeCreated="memory.timeCreated"
                    />
                    <div class="row horizontal-divider"></div>

                </div>
            </div>
        </section>
    </div>

</template>

<script>

import EntityLocation from '@/models/EntityLocation.js';
import PersonEntity from '@/models/PersonEntity.js';
import PlaceEntity from '@/models/PlaceEntity.js';
import MemoryComponent from '@/components/subcomponents/MemoryComponent.vue';
// import { onMounted } from 'vue';
import { ref } from 'vue';
import HTTPRequester from '@/models/HTTPRequester';

// const selected_point = ref(null);

const current_data = ref(null);
// const  = ref(null);

export default {
    props: {
        selected_point: null,
    },
    data() {
        return {
            type: "",
            
            active: false,
            // all_photos: [], //Array(5).fill({url: 'assets/images/test_person_img.png'})
        }
    },
    async setup(props) {
        // let primaryMemory = null, restOfMemories = null;
        let entity_data = null, memory_data = null;

        const all_photos = ref([]);
        let type = -1;

        const parseInfo = async () => {
            // console.log("Selected Point: ", props.selected_point);


            const selected_point = props.selected_point;

            await HTTPRequester.request("get_single_entity", 
            { 
                param: {
                    entityId: selected_point.id
                },
                callback: (data) => {
                    [entity_data, memory_data] = data;
                }
            });

            type = parseInt(entity_data.type);

            let entity = null;

           

            // primaryMemory = memory_data[0];
            // restOfMemories = memory_data.slice(1);

            memory_data.forEach(memory => {
                all_photos.value.push(...memory.gallery);
            });

            console.log(all_photos.value[0])


            if(type === 0){
                entity = new PersonEntity({
                    id: entity_data.id,
                    fullname: entity_data.title,
                    birth_date: entity_data.born ? new Date(entity_data.born) : null,
                    death_date: entity_data.died ? new Date(entity_data.died) : null,
                    location: new EntityLocation({address_string: entity_data.address}),
                    imgId: parseInt(entity_data.imgId)
                });
            } else if(type === 1){
                entity = new PlaceEntity({
                    id: entity_data.id,
                    name: entity_data.title,
                    build_date: entity_data.establishDate,
                    is_alive: entity_data.isAlive,
                    // location: new EntityLocation({address_string: [entity_data.neighborhood, entity_data.district, entity_data.city].filter(p=>p).join(', ')}),
                    location: new EntityLocation({address_string: entity_data.address}),

                    imgId: parseInt(all_photos.value[0])
                });
            }

            console.log(memory_data)
            console.log(entity_data)
            // data.value = memory;

            return entity;
            // -

            // Using this.selected_point_id and DB
            // this
            // this.photos = ...
            // and spawn comments
        }

        current_data.value = await parseInfo();
        if (type === 0){
            current_data.value.title = `${current_data.value.getFullName()} anısına`;
            const birth_date = current_data.value.getBirthDate() ?? '...';
            const death_date = current_data.value.getDeathDate() ?? '...';
            current_data.value.date = birth_date || death_date ? [birth_date, death_date].filter(d => d).join(' - ') : '';
            current_data.value.address = `${current_data.value.location.address_string}`;
        } else if(type === 1){
            current_data.value.title = `${current_data.value.name}`;
            current_data.value.date = current_data.value.build_date;
            current_data.value.address = `${current_data.value.location.address_string}`;
        }


        return { current_data, memory_data, all_photos };
    },
    methods: {
        
    },
    computed: {
        getActiveState(){
            return this.active;
        },
        // title() {
        //     return `${this.data.getFullName()} anısına`;
        // },
        // date() {
        //     return `${this.data.getBirthDate()} - ${this.data.getDeathDate()}`;
        // },
        // address() {
        //     return `${this.data.location.address_string}`;
        // },
    },
    mounted() {
        // this.type = this.data.type;
        // if(this.type === 'PersonMemory'){
        //     this.title = `${this.data.getFullName()} anısına`;
        //     this.date = `${this.data.getBirthDate()} - ${this.data.getDeathDate()}`;
        //     this.address = `${this.data.location.address_string}`;
        // } 
        // else if (this.type === 'PlaceMemory'){

        // }
    },
    components: {
        MemoryComponent
    }
}
</script>

<style lang="scss" scoped>
    .container {
        @include from($desktop) {
            // max-height: -0.75rem;
            overflow-y: auto;
        }
    }

    #photo-library {
        @include until($desktop){
            max-height: 15vh;
        }
        @include from($desktop){
            max-height: 32vh;
        }
        overflow-y: auto;
    }

    .entry-date, .entry-address {
        font-weight: 500;
        font-size: 14px;
    }
	img
	{
				object-fit: cover !important;
	}
</style>