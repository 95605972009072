<template>
    <Form ref="form" @submit="onNextPage" :validation-schema="schema" v-slot="{ errors }">
        <div v-if="type===0" class="form-section">
            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-2">Yakınınızın Fotoğrafı</p>
            <p class="mb-5 info">Yakınınızın anıtında yer almasını istediğiniz bir fotoğrafını bu alana ekleyin.</p>
            
            <div class="columns">
                <div class="column is-one-third-tablet">
                    <div class="columns is-vcentered is-variable is-5">
                        <div class="column">
                            <!-- <div class="file">
                                <figure class="image is-128x128">
                                    <label class="file-label">
                                        <img class="is-rounded image-upload" src="">
                                        <input class="file-input" type="file" name="image">
                                    </label>
                                </figure>
                            </div> -->
                            <DropZone 
                                    ref="drop1"
                                    dropzoneMessageClassName="drop1"
                                    :maxFiles="Number(1)"
                                    :url="HTTPRequester.get_request_endpoint_url('upload_single_file')"
                                    :uploadOnDrop="true"
                                    :multipleUpload="false"
                                    :parallelUpload="1"
                                    :acceptedFiles="['jpg', 'jpeg', 'png']"
                                    @sending="primaryImage.sending"
                                    @removedFile="primaryImage.removedFile"
                            >
                                <template v-slot:message>
                                    <div class="is-uppercase" style="height: 100%">
                                        <p>Fotoğraf seç</p>
                                    </div>
                                </template>

                            </DropZone>
                        </div>
                    </div>
                </div>
            </div>

            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-2">Diğer Fotoğraflar</p>
            <p class="mb-5 info">Yakınınızın anıtında bulunmasını istediğiniz diğer fotoğrafları bu alana ekleyebilirsiniz.</p>

            <div class="columns">
                <div class="column">
                    <DropZone 
                        ref="drop2"
                        dropzoneMessageClassName="drop2"
                        :maxFiles="Number(10)"
                        :url="HTTPRequester.get_request_endpoint_url('upload_single_file')"
                        :uploadOnDrop="true"
                        :multipleUpload="true"
                        :parallelUpload="1"
                        :acceptedFiles="['jpg', 'jpeg', 'png']"
                        @sending="otherImage.sending"
                        @removedFile="otherImage.removedFile"
                    >
                        <template v-slot:message>
                            <div class="is-uppercase" style="height: 100%">
                                <p>Fotoğraf seç</p>
                            </div>
                        </template>
                    </DropZone>
                </div>
            </div>

            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-2">Yakınınız Hakkında</p>
            <p class="mb-5 info">Yakınınızın hakkında anlatmak istediklerinizi bu alanda paylaşabilirsiniz.</p>
            <div class="field">
                <div class="control">
                    <Field v-slot="{ field }" v-model="memory" name="memory" rules="required">
                        <textarea class="textarea" placeholder="" v-bind="field" name="memory"
                        :class="{'is-danger': errors.memory }"/>
                        <!-- <div v-if="errors[0]">{{errors[0]}}</div> -->
                    </Field>
                </div>
            </div>
        </div>
        <div v-if="type===1" class="form-section">
            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-2">Mekanın Fotoğrafları</p>
            <p class="mb-5 info">Oluşturduğunuz kent belliğinde yer almasını istediğiniz fotoğrafları bu alana ekleyin.</p>

            <div class="columns">
                <div class="column">
                    <DropZone 
                        ref="drop2"
                        dropzoneMessageClassName="drop2"
                        :maxFiles="Number(10)"
                        :url="HTTPRequester.get_request_endpoint_url('upload_single_file')"
                        :uploadOnDrop="true"
                        :multipleUpload="false"
                        :parallelUpload="1"
                        :acceptedFiles="['jpg', 'jpeg', 'png']"
                        @sending="otherImage.sending"
                        @removedFile="otherImage.removedFile"
                    >
                        <template v-slot:message>
                            <div class="is-uppercase" style="height: 100%">
                                <p>Fotoğraf seç</p>
                            </div>
                        </template>
                    </DropZone>
                </div>
            </div>

            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-2">Mekan Hakkında</p>
            <p class="mb-5 info">Mekan hakkında anlatmak istediklerinizi bu alanda paylaşabilirsiniz.</p>
            <div class="field">
                <div class="control">
                    <Field v-slot="{ field }" v-model="memory" name="memory" rules="required">
                        <textarea class="textarea" placeholder="" v-bind="field" name="memory"
                        :class="{'is-danger': errors.memory }"/>
                        <!-- <div v-if="errors[0]">{{errors[0]}}</div> -->
                    </Field>
                </div>
            </div>
        </div>

        <div class="columns mt-5 is-centered">
            <div class="column mt-5 mb-5 is-three-fifths">
                <div class="columns">
                    <div class="column is-one-thirds">
                        <button type="button" @click="onPreviousPage" class="button secondary"><p class="is-uppercase">Geri Git</p></button>
                    </div>
                    <div class="column is-two-thirds">
                        <button type="submit" class="button primary"><p class="c3 is-uppercase">Kaydet ve İlerle</p></button>
                    </div>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';

import HTTPRequester from '@/models/HTTPRequester';

export default {
    props: {
        type: Number
    },
    emits: ["previousPage, nextPage"],
    components: {
        DropZone,
        Form, Field
    },
    data() {
        return {
            primaryImage: {
                validImages: [],

                removedFile: (item) => {
                    this.primaryImage.validImages = this.primaryImage.validImages.filter(i=>i.name != item.file.name);
                    console.log(this.primaryImage.validImages);
                },

                sending: (files, xhr, formData) => {
                    console.log(files, formData);
                    const callback = (e) => {
                        // console.log(this.primaryImage.validImages);
                        if(e.length > 0){
                            try {
                                const data_json = JSON.parse(e);
                                // const status = data_json["status"];
                                const imageId = data_json["data"]["id"];
                                
                                files.forEach(f => f.imageId = imageId);
                                this.primaryImage.validImages.push(...files);
                                console.log(this.primaryImage.validImages);
                            } catch(e) {
                                console.error(e);
                            }
                        }
                    }

                    xhr.onreadystatechange = () => {
                        console.log(xhr);
                        if (xhr.readyState === 4) {
                            callback(xhr.response);
                        }
                    };
                }
            },

            otherImage: {
                validImages: [],

                removedFile: (item) => {
                    this.otherImage.validImages = this.otherImage.validImages.filter(i=>i.name != item.file.name);
                    console.log(this.otherImage.validImages);
                },

                sending: (files, xhr, formData) => {
                    console.log(files, formData);
                    const callback = (e) => {
                        if(e.length > 0){
                            try {
                                const data_json = JSON.parse(e);
                                // const status = data_json["status"];
                                const imageId = data_json["data"]["id"];
                                
                                files.forEach(f => f.imageId = imageId);
                                this.otherImage.validImages.push(...files);
                                console.log(this.otherImage.validImages);
                            } catch(e) {
                                console.error(e);
                            }
                        }
                    }

                    xhr.onreadystatechange = () => {
                        console.log(xhr);
                        if (xhr.readyState === 4) {
                            callback(xhr.response);
                        }
                    };
                }
            }
        }
    },
    setup() {
        const schema = yup.object({
            memory: yup.string().min(3).required().max(5000).matches(/[^\s*].*[^\s*]/g),
        })
        
        return { schema, HTTPRequester }
    },
    methods: {
        onPreviousPage(){
            this.$emit('previousPage');
        },

        onNextPage(values){
            // console.log(values);
            this.validate();

            // -- If first post and not a place
            if(/* isPrimary && */ this.$props.type === 0){
                // if(this.primaryImage.validImages.length == 0){
                //     return;
                // }
                values.imageIds = this.primaryImage.validImages.concat(this.otherImage.validImages).map(i => i.imageId);
            } else {
                // if(this.otherImage.validImages.length == 0){
                //     return;
                // }
                values.imageIds = this.otherImage.validImages.map(i => i.imageId);
            }

            console.log("Imgs:", values.imageIds)

            this.$emit('nextPage', values);
        },

        validate(){
            if(/* isPrimary && */ this.$props.type === 0){
                // if(this.primaryImage.validImages.length == 0){
                //     this.$refs.drop1.dropzone.style.borderColor = '#a00311';
                // } else {
                //     this.$refs.drop1.dropzone.style.borderColor = '#03A062';
                // }
            } else {
                // if(this.otherImage.validImages.length == 0){
                //     this.$refs.drop2.dropzone.style.borderColor = '#a00311';
                // } else {
                //     this.$refs.drop2.dropzone.style.borderColor = '#03A062';
                // }
            }
            this.$refs.form.validate();
        }
    },
}
</script>

<style lang="scss" scoped>
    .image-upload {
        border: 1px dashed #646464;
        width: 128px !important;
        height: 128px !important;
    }
</style>