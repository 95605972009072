import { Loader } from "@googlemaps/js-api-loader"

export default class MapsAPI {
    static PUBLIC_API_KEY = "AIzaSyCYQpURl_I9ShSL9ZhQjuk3CZwI0fKw3u8";
    static DEV_API_KEY = "AIzaSyBNpbnMPHu8Yse9QmxhsCHlTrICGRqeCRQ";
    static loader = new Loader({
        apiKey: this.DEV_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
    static isReady = false;

    static staticConstructor = (() => {
        this.loader.load().then((google) => {
            this.isReady = true;
            this.google = google;

            this.EARTHQUAKE_BOUNDS = new this.google.maps.LatLngBounds(
                new this.google.maps.LatLng(35.764343,33.903809),
                new this.google.maps.LatLng(38.985033,41.396484));

            this.geocoder = new this.google.maps.Geocoder();
        });
    })()

    static EARTHQUAKE_BOUNDS = null;
    static geocoder = null;
    

}