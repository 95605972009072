<template>
    <div class="row mt-2">
        <div class="columns">
            <div class="column is-full">
                <p class="memory-content is-size-6-mobile is-size-6-tablet has-text-justified" v-html="content">
                </p>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="columns">
            <div class="column is-italic">
                <p class="memory-author" ref="relation_text"><span class="is-capitalized">{{fullname}}</span></p>
            </div>
        </div>
    </div>
    <div v-if="gallery.length">
        <div class="row horizontal-divider short"></div>

        <div class="row mt-2 mb-4">
            <div ref="photo_library" class="columns is-mobile is-variable is-multiline is-2">
                <div v-for="photo in gallery" :key="photo" class="column is-half-mobile is-2">
                    <div>
                        <figure class="image is-1by1" @click="showImage=true; fullImgId=photo;">
                            <img :src="`https://hafizakapisi.com.tr/media/image/thumb.php?id=${photo}`" alt="" class="is-square">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="imgFull" v-bind:style="{'background-image':'url(https://hafizakapisi.com.tr/media/image/full.php?id='+fullImgId+')'}" v-bind:class="{fadeIn:showImage}">
		<div class="buttonClose" @click="showImage=false;">✕</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showImage: false,
			fullImgId:0
		}
	},
    props: {
        fullname: String,
        content: String,
        relation: String,
        gallery: Array,
        timeCreated: Date
    },
    mounted() {
        // console.log("v", this.$refs.photo_library);
        if (this.relation){
            this.$refs.relation_text.innerText += ", " + this.relation.toLowerCase();
        }

        if (this.timeCreated){
            let timeCreated = new Date(this.timeCreated);
            this.$refs.relation_text.innerText += " | " + [timeCreated.getDay().pad(2), (timeCreated.getMonth() + 1).pad(2), timeCreated.getFullYear()].join('.');
        }

    }
}
</script>

<style lang="scss" scoped>
    .memory-content {
        font-size: 1rem;
        color: #323232;
        font-weight: 400;
        white-space: pre-line;
    }

    .memory-author {
        font-size: 0.875rem;
        font-weight: 400;
    }
	img
	{
		object-fit: cover !important;
		cursor:pointer;
	}
	#imgFull
	{
		position:fixed;
		left:0px;
		top:0px;
		width:100%;
		height:100%;
		background-color:#000000D0;
		
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 50%;
		pointer-events:none;
		opacity:0;
		transition: all 0.5s ease;
		
		.buttonClose
		{
			cursor:pointer;
			font-size:2em;
			color:white;
			position: absolute;
			right: 26px;
			top: 20px;
		}
	}
	#imgFull.fadeIn
	{
		pointer-events:auto;
		opacity:1;
	}
</style>