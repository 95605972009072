
<template class="component">
    <section id="contribution-component" class="form">
        <section class="hero is-medium">
            <div class="hero-head">
                <div class="columns is-centered">
                    <div class="column is-four-fifths">
                        <div class="close-button">
                            <button @click="$emit('onClose')" class="delete is-large"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hero-body">
                <div class="container">
                    <div class="mt-5"></div>
                    <div class="columns is-centered">
                        <div class="column is-four-fifths">
                            <div>
                                <h1 class="title is-size-2">
                                    <div v-if="contribution_type===0" >
                                        Sevdiğinizin anısına bir sayfa oluşturun.
                                    </div>
                                    <div v-else-if="contribution_type===1">
                                        Bir kent belleği kaydı oluşturun.
                                    </div>
                                </h1>

                                <h2 class="subtitle mt-2 is-size-5">
                                    <div v-if="contribution_type===0">
                                        Bellek kayıtları, 6 Şubat depreminde kaybettiğimiz yakınlarımız, ailelerimiz, akrabalarımız, sevdiklerimiz için anma araçlarıdır. 
                                    </div>
                                    <div v-else-if="contribution_type===1">
                                        Kent belleği kayıtları, 6 Şubat depreminde kaybettiğimiz kentlerimize dair anılarımızı kaydetme ve saklama araçlarıdır. 
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="navbar" class="tabs is-centered is-uppercase is-variable has-text-weight-bold">
                <ul>
                    <li class="is-active">
                        <a>
                            <div v-if="contribution_type===0">
                                Temel Bilgiler
                            </div>
                            <div v-if="contribution_type===1">
                                Adres Bilgileri
                            </div>
                        </a>
                    </li>
                    <li><a>Anılar</a></li>
                    <li><a>Sizin Hakkınızda</a></li>
                </ul>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="columns m-5 is-centered">
                    <div id="form-content" class="column is-four-fifths">
                        <div v-show="active_page===0">
                            <BasicsForm ref="basics_form"
                                :type="contribution_type"
                                @next-page="go_next_page"
                            />    
                        </div>
                        
                        <div v-show="active_page===1">
                            <MemoriesForm ref="memories_form"
                                :type="contribution_type"
                                @next-page="go_next_page"
                                @previous-page="go_previous_page"
                            />
                        </div>
                        <div v-show="active_page===2">
                            <AboutForm ref="about_form"
                                :type="contribution_type"
                                @next-page="submit"
                                @previous-page="go_previous_page"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>

import { configure } from 'vee-validate';

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

import BasicsForm from '@/components/forms/BasicsForm.vue'
import MemoriesForm from '@/components/forms/MemoriesForm.vue'
import AboutForm from '@/components/forms/AboutForm.vue'

import { useReCaptcha } from 'vue-recaptcha-v3'
import HTTPRequester from '@/models/HTTPRequester'

export default {
    props: {
        isPrimaryContribution: Boolean,
        contribution_type: Number
    },
    data() {
        return {
            active_page: 0,
            num_pages: 3,

            form_values: {}
        }
    },
    setup() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

        const recaptcha = async () => {
            // (optional) Wait until recaptcha has been loaded.
            await recaptchaLoaded()

            // Execute reCAPTCHA with action "submit_form".
            const token = await executeRecaptcha('submit_form')
            console.log("Recaptcha", token);
            // await executeRecaptcha('submit_form')
        
            // Do stuff with the received token.
        }

        return {
            recaptcha
        }
    },
    methods: {
        prepare_for_submission(form_values, isPrimary = true){
            let imgId = isPrimary ? form_values.imageIds[0] : -1;
            let gallery = [];
            if (form_values.imageIds.length > 1 && isPrimary)
                gallery = form_values.imageIds.slice(1);
            if(!isPrimary || form_values.type == 1)
                gallery = form_values.imageIds;
                
            return {
                owner: {
                    name: form_values.owner_name_surname || "",
                    phone: form_values.owner_phone || "",
                    email: form_values.owner_email || "",
                },
                entity: // if new entity is created
                {
                    type: form_values.type, // 0 if person, 1 if cultural heritage
                    imgId: imgId,
                    coord: form_values.coord || "",
                    cityId: 0,
                    districtId: 0,
                    neighborhoodId: 0,
                    address:form_values.address || "",
                    title: form_values.title || "", // if cultural heritage
                    name: form_values.name || "", // if person
                    born: form_values.born || "", // if person format: 2023-02-14
                    died: form_values.died || "", // if person format: 2023-02-14
                    
                    isAlive: 0, // if cultural heritage
                    establishDate: form_values.establishDate || "", // if cultural heritage format: 2023-02-14
                },
                //entityId:1, // if added to an existing entity
                memory: {
                    relationship: form_values.owner_relation || "", // if person
                    info: form_values.memory || "" // memory text
                },
                gallery: gallery // list of image id's
            }
        },
        submit(values) {
            Object.assign(this.form_values, values);

            const form_values = this.prepare_for_submission(this.form_values);
            
            // const schemas = [this.$refs.basics_form.schema, this.$refs.memories_form.schema, this.$refs.about_form.schema];
            // let validationPromises = [];
            // schemas.forEach(async (schema, idx) => {
            //     const promise = schema.validate(this.form_values)
            //     .then(()=>{ 
            //             console.log("Resolved!");
            //             return Promise.resolve();
            //         })
            //     .catch(() => { return Promise.reject(idx); });
            //     validationPromises.push(promise);
            // });


            // Promise.all(validationPromises)
            //     .then(() => {
            //         this.recaptcha().then(()=>{
            //             HTTPRequester.request("set_single_memory", { 
            //                 param: form_values,
            //                 callback: (response)=>{
            //                     console.log(response)
            //                 }
            //             })
            //         });
            //     })
            //     .catch(idx => {
            //         this.go_to_page(idx);
            //         this.forms[idx].validate();
            //     })

            this.recaptcha().then(()=>{
                HTTPRequester.request("set_single_memory", { 
                    param: form_values,
                    callback: (response)=>{
                        if(response){
                            if(response.status == 1){
                                this.$emit('success');
                                return;
                            }
                        }
                        // Show error modal
                        console.error(response);
                    }
                })
            });

        },
        go_next_page(values){
            Object.assign(this.form_values, values);
            if(this.active_page + 1 >= this.num_pages)
                return;
            ++this.active_page;
            this.update_navbar();
        },
        go_previous_page(){
            if(this.active_page - 1 < 0)
                return;
            --this.active_page;
            this.update_navbar();
        },
        go_to_page(number){
            if(number < 0 || number > this.num_pages)
                return;
            this.active_page = number;
            this.update_navbar();
        },
        update_navbar(){
            this.navbar_items.forEach(item => {
                item.classList.remove('is-active');
            });
            this.navbar_items[this.active_page].classList.add('is-active');

            this.navbar.scrollIntoView({ top: 0, behavior: 'smooth' });
            this.navbar_items[this.active_page].scrollIntoView({ inline: 'center', top: 0, behavior: 'smooth' });
        }
    },
    mounted() {
        const { basics_form, memories_form, about_form } = this.$refs;
        this.forms = [basics_form, memories_form, about_form]
        // console.log(this.forms);

        // this.navbar = document.querySelector('.tabs');
        this.navbar = this.$refs.navbar;
        this.navbar_items = this.navbar.querySelectorAll('.tabs ul li');
    },
    components: {
        BasicsForm,
        MemoriesForm, 
        AboutForm
    },
    computed: {
        getActiveState(){
            return this.active;
        }
    }
}
</script>

<style lang="scss" scoped>
    .component {
        // background-color: #ffffff !important;
        // position: absolute;
    }

    .form {
        height: 100%;
        overflow-y: scroll;
        width: 100%;
        z-index: 3;
        top: 0;

        position: fixed;
        background-color: #ffffff !important;
        // background: linear-gradient(0deg, #3A7186 0%, rgba(254, 219, 199, 0) 100%);
    }

    .hero {
        background: none;
        background: linear-gradient(180deg, #86C8D4 0%, rgba(254, 219, 199, 0) 100%);
        // padding-bottom: 4.5rem !important;
    } 

    .hero-head > .columns {
        margin-left: 0;
        margin-right: 0;
    }

    .close-button {
        position: relative;
        float: right;
        top: 2rem;
        right: 2rem;
    }

    #form-content {
        margin-top: 2rem;
        // transition: height 0.2s ease-out;
        min-height: 100vh;
    }

    .hero-body p {
        color: $d-primary-text-color !important;
    }

    .tabs {
        background: none;
    }

    .hero .tabs ul {
        // align-items: center;
        border-bottom-color: #dbdbdb;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        // display: flex;
        // flex-grow: 1;
        // flex-shrink: 0;
        // justify-content: flex-start;
    }

    .tabs a:hover {
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-bottom-color: $d-primary-text-color;
        color: $d-primary-text-color;
    }

    .tabs li.is-active a {
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-bottom-color: $d-primary-text-color;
        color: $d-primary-text-color;
    }

    .hero .tabs li:not(.is-active) a {
        color: #D9D9D9 !important;
    }

    li:not(:last-child) { 
       margin-right: 5rem;  
    }
    
    /deep/ p.info {
        color: #1B2632;
    }
</style>