<template>
    <Form ref="form" @submit="onNextPage" :validation-schema="schema" v-slot="{ errors }">
        <div v-if="type===0" class="form-section">
            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-5">
                Yakınınızın Bilgileri
            </p>
            
            <div class="columns is-variable is-5">
                <div class="column is-full">
                    <div class="field">
                        <div class="control">
                            <Field name="name" class="input" type="text" placeholder="İsmi ve Soyismi*" 
                            :class="{'is-danger': errors.name }"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="birth_place" class="input" type="text" placeholder="Doğum Yeri"
                            :class="{'is-danger': errors.birth_place }" />
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="columns is-variable is-5">
                <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="born" class="input" type="text" placeholder="Doğum Tarihi (örn. 02.05.1954)"
                            :class="{'is-danger': errors.born }" />
                        </div>
                    </div>
                </div>
                <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="died" class="input" type="text" placeholder="Ölüm Tarihi (örn. 06.02.2023)" 
                            :class="{'is-danger': errors.died }" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="type===1" class="form-section">
            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-5">
                Mekan Bilgileri
            </p>
            
            <div class="columns is-variable is-5">
                <div class="column is-full">
                    <div class="field">
                        <div class="control">
                            <Field name="title" class="input" type="text" placeholder="Mekanın ismi*" 
                            :class="{'is-danger': errors.title }"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="birth_place" class="input" type="text" placeholder="Yakınınızın Doğum Yeri"
                            :class="{'is-danger': errors.birth_place }" />
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="columns is-variable is-5">
                <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="born" class="input" type="text" placeholder="Yakınınızın Doğum Tarihi (örn. 02.05.1954)"
                            :class="{'is-danger': errors.born }" />
                        </div>
                    </div>
                </div>
                <div class="column is-half">
                    <div class="field">
                        <div class="control">
                            <Field name="died" class="input" type="text" placeholder="Yakınınızın Ölüm Tarihi (örn. 06.02.2023)" 
                            :class="{'is-danger': errors.died }" />
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="form-section">
            <p class="c2 is-uppercase has-text-weight-medium is-size-5 mt-5 mb-5">Konumu</p>
            <div class="columns">
                <div class="column is-full">
                    <div class="field">
                        <div class="control has-icons-right is-large is-loading">
                            <Field @input="addressChangeHandler" @blur="addressResetHandler" ref="address" name="death_place" class="input" type="text" placeholder="Adres*" 
                            :class="{'is-danger': errors.death_place }" />
                                <!-- <span class="icon is-right">
                                    <font-awesome-icon icon="fa-regular fa-map" />
                                </span> -->
                        </div>
                    </div>
                    <p class="mb-5 info is-italic" style="font-size: 14px">Haritayı hareket ettirerek konumu düzenleyebilir, veya harita üzerinde bir noktaya tıklayarak yeni konum seçebilirsiniz.</p>
                    <div id="small-map"></div>
                </div>
            </div>
        </div>

        <div class="columns mt-5 is-centered">
            <div class="column mt-5 mb-5 is-three-fifths">
                <button class="button is-full"><p class="c3 is-uppercase">Kaydet ve İlerle</p></button>
            </div>
        </div>
    </Form>
</template>

<script>

import { SmallMap } from '@/models/Map'
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import MapsAPI from '@/models/MapsAPI'


export default {
    props: {
        type: Number
    },
    emits: ["nextPage"],
    data(){
        return{
            address: null,
            pickedPlace: null,
        }
    },
    setup(props) {
        const date_regex = /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:([2-9]\d)?\d{4})$/

        let schema = null;
        if(props.type === 0){
            schema = yup.object({
                name: yup.string().required().max(100).matches(/^[a-zA-ZğüşöçıİĞÜŞÖÇ]+(([',. -][a-zA-ZğüşöçıİĞÜŞÖÇ ])?[a-zA-ZğüşöçıİĞÜŞÖÇ]*)*$/u),
                // birth_place: yup.string().required().max(100),
                born: yup.string().matches(date_regex),
                died: yup.string().matches(date_regex),
                // death_place: yup.string().required().max(100),
            })
        } else if(props.type === 1){
            schema = yup.object({
                title: yup.string().min(3).required().max(100).matches(/[^\s*].*[^\s*]/g), // cannot contain only whitespaces
                // isAlive:0, // if cultural heritage
                // establishDate: yup.string().matches(date_regex).required(),
                // birth_place: yup.string().required().max(100),
                // born: yup.string().matches(date_regex).required(),
                // died: yup.string().matches(date_regex).required(),
                // death_place: yup.string().required().max(100),
            })
        }
        
        return { schema }
    },
    components: {
        Form, Field
    },
    methods: {
        onNextPage(values){
            if(!this.pickedPlace || !this.pickedLatLng){
                this.$refs.address.$el.style.borderColor = '#a00311';
                return;
            }
            values["address"] = this.pickedPlace.formatted_address;
            values["coord"] = `${this.pickedLatLng[0]}, ${this.pickedLatLng[1]}`;

            if(values["born"])
                values["born"] = values["born"].split(".").map(s=>parseInt(s).pad(2)).reverse().join('-');
            else
                values["born"] = ""

            if(values["died"])
                values["died"] = values["died"].split(".").map(s=>parseInt(s).pad(2)).reverse().join('-');
            else
                values["died"] = ""


            console.log(values, this.pickedPlace);

            this.$emit('nextPage', values);
        },
        validate(){
            this.$refs.form.validate();
        },
        // addressChangeHandler(){
        //     if(this.addressChangeTimeout){
        //         clearTimeout(this.addressChangeTimeout);
        //         this.addressChangeTimeout = null;
        //     }

        //     // this.addressChangeTimeout = setTimeout(() => {
        //     //     console.log(this.death_place);
        //     //     // Send req here
        //     // }, 250);
        // },
        addressResetHandler(){
            if(this.addressChangeTimeout){
                clearTimeout(this.addressChangeTimeout);
                this.addressChangeTimeout = null;
            }

            this.addressChangeTimeout = setTimeout(() => {
                console.log(this.pickedPlace)
                if(!this.pickedPlace){
                    return;
                }
                if(this.pickedPlace.formatted_address){
                    if(this.pickedPlace.name)
                        this.$refs.address.$el.value = `(${this.pickedPlace.name}) ${this.pickedPlace.formatted_address}`;
                    else
                        this.$refs.address.$el.value = this.pickedPlace.formatted_address;
                }
                else{
                    this.$refs.address.$el.value = ""
                    this.pickedPlace = null;
                }
            }, 100);

        }
    },

    mounted() {
        this.map = new SmallMap();

        this.autocomplete = new MapsAPI.google.maps.places.Autocomplete(this.$refs.address.$el, {
            bounds: MapsAPI.EARTHQUAKE_BOUNDS,
            componentRestrictions: {country: 'tr'},
            strictBounds: true
        });

        MapsAPI.google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            // console.log("here", this.autocomplete.getPlace())
            this.pickedPlace = this.autocomplete.getPlace();
            
            if(this.addressChangeTimeout){
                clearTimeout(this.addressChangeTimeout);
                this.addressChangeTimeout = null;
            }

            console.log(this.pickedPlace.formatted_address);
            if(this.pickedPlace.formatted_address){
                if(this.pickedPlace.name)
                    this.$refs.address.$el.value = `(${this.pickedPlace.name}) ${this.pickedPlace.formatted_address}`;
                else
                    this.$refs.address.$el.value = this.pickedPlace.formatted_address;
            } else {
                this.$refs.address.$el.value = "";
                this.pickedPlace = null;
                return
            }
                       
            this.pickedLatLng = [this.pickedPlace.geometry.location.lat(), this.pickedPlace.geometry.location.lng()]
            this.map.flyTo({
                center: this.pickedLatLng?.slice().reverse(),
                zoom: 15,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
            this.map.fire("flystart");

        });

        let flying = false;
        this.map.on("flystart", () => {
            flying = true;
        });

        this.map.on("flyend", () => {
            flying = false;
        });

        const clamp_to_boundaries = () =>{
            this.map.flyTo({
                center: this.pickedLatLng?.slice().reverse(),
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
            this.map.fire("flystart");
        }

        this.map.on("moveend", () => {
            if (flying) {
                this.map.fire("flyend");
            } else { // actual moveend invoked by user drag
                let center = this.map.getCenter();

                var latlng = new MapsAPI.google.maps.LatLng(center.lat, center.lng);
                
                if(!MapsAPI.EARTHQUAKE_BOUNDS.contains(latlng)){
                    clamp_to_boundaries();
                    return;
                }

                MapsAPI.geocoder.geocode({'latLng': latlng}, (results, status) => {
                    if(status === 'OK'){
                        results = results.filter(r => !r.types.includes('plus_code'));
                        if(results.length > 0){
                            const country_candidate = results[0].address_components[results[0].address_components.length-1];
                            if (country_candidate.types.includes("country")){
                                if(country_candidate.short_name !== 'TR'){
                                    clamp_to_boundaries();
                                    return;
                                }
                            }
                            console.log(results);
                            if(results[0].name)
                                this.$refs.address.$el.value = `(${results[0].name}) ${results[0].formatted_address}`;
                            else
                                this.$refs.address.$el.value = results[0].formatted_address;

                            this.pickedPlace = results[0];
                            this.pickedLatLng = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
                        }
                    }
                });
            }
        });
    }
}
</script>

<style lang="scss" scoped>
    #small-map {
        @include until($desktop){
            min-height: 30vh;
        }
        @include from($desktop){
            min-height: 40vh;
        }
        width: 100%;
    }
</style>
