/* eslint-disable no-unused-vars */

export default class BaseEntity {
    constructor({ id, location }){
        this.type = this.constructor.name;

        this.id = id;
        this.location = location;
    }
    
    getAddress() {
        return this.location.address_string;
    }

    toDMY(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return { day: day, month: month, year: year };
    }
}

/* eslint-enable no-unused-vars */