/* eslint-disable no-unused-vars */

import BaseEntity from '@/models/BaseEntity.js'

export default class PlaceEntity extends BaseEntity {
    constructor({id, name, build_date, location, is_alive, imgId}){
        super({id, location});
        this.name = name;
        this.build_date = build_date;
        this.is_alive = is_alive;

        this.imgId = imgId;
    }
}

/* eslint-enable no-unused-vars */