<template class="component">
    <section id="map-component" class="hero is-fullheight is-fullwidth">
        <div class="hero-head">
            <Transition name="arrowed-button-area">
                <div class="arrowed-button-area top" v-if="!show_search && !show_info/* && active*/">
                    <div class="rows has-text-centered">
                        <div @click="onArrowClicked" class="row clickable">
                            <div class="arrowed-button">
                                <img class=rotated src="@/assets/images/arrow.svg"/>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <a v-show="!show_search && active" @click="showSearch(true)" class="search-button-container">
                                <div class="search-button">
                                    <img src="@/assets/images/search.svg"/>
                                </div>
                            </a>
                            <div class="container">
                                <div class="columns is-centered is-uppercase is-mobile" v-if="false">
                                    <div @click="onCreateCityMemoryClicked" class="column is-one-third clickable">
                                        <div class="navbar-button">
                                            <span class="dot city"></span>
                                            <p class="is-underlined">KENT BELLEĞİ <span class="desktop">OLUŞTUR</span></p>
                                        </div>
                                    </div>
                                    <div @click="onArrowClicked" class="column is-one-third clickable">
                                        <div id="return-to-landing-navbar-button" class="navbar-button">
                                            <p class="desktop">Giriş</p>
                                        </div>
                                    </div>
                                    <div @click="onCreatePersonMemoryClicked" class="column is-one-third clickable">
                                        <div class="navbar-button">
                                            <span class="dot person"></span>
                                            <p class="is-underlined">KİŞİ BELLEĞİ <span class="desktop">OLUŞTUR</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        <div class="hero-body is-fullheight">
            <div id='map'></div>
            <div @click="()=>{ hideInfo(); hideSearch(); }" v-if="show_info || show_search" id="click-to-hide"></div>

            <div id="search-wrapper">
                <section class="section">
                    <div class="columns custom">
                        <Transition name="search-transition">
                            <SearchComponent v-if="show_search"
                                @onMouseOverEntity="(entity) => flyOver(entity)"
                                @on-close="hideSearch()"
                                @on-entity-click="(entity)=>goToEntity(entity)"
                                :selected_point="selected_point"
                                :all_data="all_data" />
                        </Transition>
                    </div>
                </section>
            </div>

            <div id="info-wrapper">
                <section class="section">
                    <div class="columns custom">
                        <Suspense>
                            <template #default>
                                <Transition name="info-transition">
                                    <InfoComponent v-if="show_info" :selected_point="selected_point" />
                                </Transition>
                            </template>
                            <template #fallback>
                                Loading...
                            </template>
                        </Suspense>
                    </div>
                </section>
            </div>
        </div>
    <div class="hero-foot"></div>
  </section>
</template>

<script>
import InfoComponent from '@/components/InfoComponent.vue'
import SearchComponent from '@/components/SearchComponent.vue'
import { MainMap } from '@/models/Map';
import HTTPRequester from '@/models/HTTPRequester';

// import axios from 'axios';


export default {
    props: {
        active: Boolean
    },
    data() {
        return {
            all_data: [],
            show_search: false,
            show_info: false,
            map: null,
            selected_point: null,
            isMobile: navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i),
            // map_shift: 0.3,
        }
    },
    methods: {
        reloadData(){
            HTTPRequester.request("get_list_dot", 
            { 
                callback: (data) => {
                    const [ person_data, place_data ] = data;
                    // console.log(person_data, place_data);
                    this.all_data = person_data.concat(place_data);
                    if(!this.map.loaded())
                        this.map.on('load', () => { this.map.populate(person_data, place_data) });
                    else
                        this.map.populate(person_data, place_data);
                }
            });
        },
        onArrowClicked(){
            this.$emit('onArrowClicked');
        },
        onCreateCityMemoryClicked(){
            this.$emit('onCreateCityMemoryClicked');
        },
        onCreatePersonMemoryClicked(){
            this.$emit('onCreatePersonMemoryClicked');
        },
        showInfo() {
            this.show_info = true;
            
            var padding = null;
            if(this.isMobile) {
                padding = {
                    bottom: window.innerHeight / 1.5
                }
            } else {
                padding = {
                    right: window.innerWidth / 2
                } 
            }
            this.map.easeTo({
                padding: padding,
                center: this.selected_point.coordinates,
                zoom: Math.max(10, this.map.getZoom())
            });
        },
        hideInfo() {
            this.show_info = false;
            let props = {
                padding: {
                    bottom: 0,
                    right: 0,
                    left: 0
                }
            };

            props.center = this.map.getCenter();
            if(this.selected_point)
                if(this.selected_point.coordinates)
                    props.center = this.selected_point.coordinates;
            
            this.map.easeTo(props);

            // this.map.setHighlight(this.selected_point);
            this.map.resetHighlight();
            this.selected_point = null;
        },
        showSearch(mapfly = true) {
            this.show_search = true;
            
            var padding = null;
            if(this.isMobile) {
                padding = {
                    bottom: window.innerHeight / 1.5
                }
            } else {
                padding = {
                    left: window.innerWidth / 3
                } 
            }

            let props = {
                padding: padding
            };

            if(mapfly){
                props.center = this.map.getCenter();
                // if(this.selected_point)
                //     if(this.selected_point.coordinates)
                //         props.center = this.selected_point.coordinates;

                
                this.map.easeTo(props);
            }
        },
        hideSearch(mapfly = true) {
            this.show_search = false;
            let props = {
                padding: {
                    bottom: 0,
                    left: 0,
                    right: 0
                }
            };

            if(mapfly){
                props.center = this.map.getCenter();
                if(this.selected_point)
                    if(this.selected_point.coordinates)
                        props.center = this.selected_point.coordinates;

                this.map.easeTo(props);
            }
        },
        // getDist(p1, p2) {
        //     const [ lat1, lon1 ] = p1;
        //     const [ lat2, lon2 ] = p2;
        //     const dist = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371;
        //     return dist;
        // },
        goToEntity(entity){
            this.hideSearch(false);
            this.selected_point = {id: entity.id, coordinates: entity.coordinates };
            this.showInfo();
        },
        flyOver(entity){
            this.map.easeTo({ center: entity.location.coordinates });
        }
    },
    components: {
        SearchComponent, InfoComponent
    },
    mounted() {
        this.$emit('mounted');
        // this.HTTPRequester = new HTTPRequester();
        this.map = new MainMap( 
            {
                container: 'map', // container ID
                style: 'mapbox://styles/asmevren/cle3olp6b005i01temkssjc8e', // style URL
                center: [37.566668, 37.200001], // starting position [lng, lat]
                zoom: 7, // starting zoom,
                attributionControl: false,
            },
            (selected_point) => {
                // this.selected_point = {id: selected_point.id, coordinates: selected_point.coordinates };
                // // console.log(this.selected_point, selected_point)
                // console.log(selected_point)
                // this.showInfo();
                this.goToEntity(selected_point);
            }
        );


        this.map.on('resized', ()=>{
            this.$emit('resized');
        })
        
        this.reloadData();

        // HTTPRequester.request("get_list_district", 
        // {
        //     // param: {
        //     //     cityId:34
        //     // },
        //     callback:
        //         (data) => {
        //             console.log(data);
        //             // this.map.populate(points.data);
        //         }
        //     }
        // );

        // (async ()=>{
        //     const {data} = await axios({
        //         method: 'post',
        //         url: `https://6subat.hafizasi.com/data/get.list.dot.php`,
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         },
        //         // data: {
        //         //     cityId: 34,
        //         // },
        //     });
        //     console.log(data);
        // })()

    }
}
</script>

<style lang="scss" scoped>
    .search-button-container {
        width: 60px;
        height: 60px;
        z-index: 1;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        background: #FEE6D9;
        border-radius: 50%;

        @include until($desktop){
            position: fixed;
            left:0;
            right:0;
            bottom: 1.8rem;
            margin: auto;
        }

        @include from($desktop){
            position: absolute;
            left: 10vh;
            top: 5vh;
        }
    }

    .search-button {
        height: 100%;
    }

    .search-button img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .arrowed-button {
        padding-top: 1vh;
    }

    .component {
        background-color: $d-background-color;
        overflow: hidden
    }

    .hero-head {
        position: absolute;
        width: 100%;
    }

    .hero-body {
        position: relative;
        // height: 100% !important;
        // width: 100% !important;
    }

    // .mapbox-container{
    //     position: relative;
    //     height: 100% !important;
    //     width: 100% !important;
    // }

    #map {
        // position: absolute;
        height: 100vh;
        width: 100%;
        // overflow: hidden;
    }

    .hero-body {
        padding: 0;
    }

    .navbar-button > * {
        display: inline-block;
    }

    .navbar-button {
        // white-space: nowrap;
    }

    #search-wrapper {
        @include from($desktop) {
            height: 100%;
        }
        
        @include until($desktop) {
            margin-top: 70vh;
            min-height: 30vh;
            z-index: 3;
        }

        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        overflow: hidden;
        z-index: 3;
    }

    #search-wrapper > .section {
        min-height: inherit;
        height: 100%;
        padding: 0;
    }

    #info-wrapper {
        @include from($desktop) {
            height: 100%;
        }
        
        @include until($desktop) {
            margin-top: 70%;
            min-height: 30%;
            z-index: 3;
        }

        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        overflow: hidden;
        z-index: 3;
    }

    #click-to-hide {
        height: 100%;
        width: 100%;
        background-color: #ffffff45;
        position: absolute;
        top: 0;
    }

    #info-wrapper > .section {
        min-height: inherit;
        height: 100%;
        padding: 0;
    }

    .columns.custom {
        height: inherit;
        min-height: inherit;
        margin: 0;
    }

    #info-wrapper {
        .column.custom {
            @include from($desktop){
                padding-top: 6rem;
                box-shadow: -20px 0px 20px 20px #fee6d9;
            }

            @include until($desktop){
                box-shadow: 0px -20px 20px 20px #fee6d9;
            }
        }
    }

    .column.custom {
        height: inherit;
        min-height: inherit;

        background-color: #FEE6D9;

        position: relative;
        overflow: auto;
        pointer-events: all;
    }

    .info-transition-enter-active,
    .info-transition-leave-active {
        transition: transform 0.5s ease, opacity 0.6s ease-out;
    }

    .info-transition-enter-from,
    .info-transition-leave-to {
        @include from($desktop){
            transform: translateX(100%);
        }

        @include until($desktop){
            transform: translateY(100%);
        }
        opacity: 0;
    }

    .search-transition-enter-active,
    .search-transition-leave-active {
        transition: transform 0.4s ease, opacity 0.3s ease-out;
    }

    .search-transition-enter-from,
    .search-transition-leave-to {
        @include from($desktop){
            transform: translateX(-100%);
        }

        @include until($desktop){
            transform: translateY(100%);
        }
        opacity: 0;
    }

</style>