import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken = 'pk.eyJ1IjoiYXNtZXZyZW4iLCJhIjoiZUFOWHRBTSJ9.DKvv4ozL5qvslCxVE7X4TQ';
import 'mapbox-gl/dist/mapbox-gl.css';
import GeoJSON from 'geojson';

class BaseMap extends mapboxgl.Map {
    constructor(props) {        
        if (!props){            
            props = {
                container: 'map', // container ID
                style: 'mapbox://styles/asmevren/cle3olp6b005i01temkssjc8e', // style URL
                center: [35.237220, 38.932572], // starting position [lng, lat]
                zoom: 4, // starting zoom,
                attributionControl: false,
            };
        }
        super(props);
        this.source_id = 'entities';
        
        this.on('load', () => {
            this.resize();
            this.fire('resized');
        });

        // this.addControl(new mapboxgl.NavigationControl());
    }
}

class MainMap extends BaseMap {
    constructor(props, pointClickCallback) {        
        if (!props){
            props = {
                container: 'map', // container ID
                style: 'mapbox://styles/asmevren/cle3olp6b005i01temkssjc8e', // style URL
                center: [37.566668, 37.200001], // starting position [lng, lat]
                zoom: 7, // starting zoom,
                attributionControl: false,
            };
        }
        super(props);

        this.pointClickCallback = pointClickCallback;

        this.person_source_id = 'persons';
        this.place_source_id = 'places';
        
        this.on('load', () => {
            this.addSource(this.person_source_id, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                },
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50),
                generateId: true
            });

            this.addSource(this.place_source_id, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                },
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
                generateId: true
            });    

            this.createLayer(this.person_source_id, '#9747FF');
            this.createLayer(this.place_source_id, '#FF3D00');
        });
    }

    createLayer(source_id, color){
        const clusters_id = source_id + '_clusters';
        const cluster_count_id = source_id + '_cluster-count';
        const unclustered_point_id = source_id + '_unclustered-point';
        this.addLayer({
            id: clusters_id,
            type: 'circle',
            source: source_id,
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': color,
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        });

        this.addLayer({
            id: cluster_count_id,
            type: 'symbol',
            source: source_id,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 16,
            },
            paint: {
                "text-color": "#fff"
            }
        });

        this.addLayer({
            id: unclustered_point_id,
            type: 'circle',
            source: source_id,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': color,
                'circle-radius': 10,
                'circle-stroke-width': 
                [
                    'case',
                    ['boolean', ['feature-state', 'highlight'], false],
                    2,
                    0
                ],
                // 'circle-stroke-width': 1,
                // 'circle-stroke-color': color,
                'circle-opacity': 
                [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,
                    0.5
                ]
            }
        });

        // inspect a cluster on click
        this.on('click', clusters_id, (e) => {
            const features = this.queryRenderedFeatures(e.point, {
                layers: [clusters_id]
            });
            const clusterId = features[0].properties.cluster_id;
            this.getSource(source_id).getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    this.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        this.on('click', unclustered_point_id, (e) => {
            // const coordinates = e.features[0].geometry.coordinates.slice();
            // const id = e.features[0].properties.id;
            let selected_point = e.features[0].properties;
            selected_point.coordinates = e.features[0].geometry.coordinates;
            console.log(selected_point)
            
            this.setHighlight(e);
            this.pointClickCallback(selected_point);
        });

        this.on('mouseenter', clusters_id, () => {
            this.getCanvas().style.cursor = 'pointer';
        });
        this.on('mouseleave', clusters_id, () => {
            this.getCanvas().style.cursor = '';
        });

        var hoveredId = null;
        this.on('mouseenter', unclustered_point_id, (e) => {
            this.getCanvas().style.cursor = 'pointer';

            if (e.features.length > 0) {
                if (hoveredId !== null) {
                    this.setFeatureState(
                        { source: source_id, id: hoveredId },
                        { hover: false }
                    );
                }
                hoveredId = e.features[0].id;
                this.setFeatureState(
                    { source: source_id, id: hoveredId },
                    { hover: true } 
                );
            }
        });
        this.on('mouseleave', unclustered_point_id, () => {
            this.getCanvas().style.cursor = '';

            if (hoveredId !== null) {
                this.setFeatureState(
                    { source: source_id, id: hoveredId },
                    { hover: false }
                );
            }
            hoveredId = null;
        });
    }
    
    populate(person_data, place_data) {
        const person_geojson = GeoJSON.parse(person_data, {Point: ['lat', 'lng']})
        const place_geojson = GeoJSON.parse(place_data, {Point: ['lat', 'lng']})

        this.getSource(this.person_source_id).setData(person_geojson);
        this.getSource(this.place_source_id).setData(place_geojson);
    }

    setHighlight(e){
        this.resetHighlight();
        this.highlightedPoint = e.features[0].id;
        this.setFeatureState(
            { source: this.person_source_id, id: this.highlightedPoint },
            { highlight: true }
        );
    }

    resetHighlight() {
        if(this.highlightedPoint != null){
            const id = this.highlightedPoint;
            this.setFeatureState(
                { source: this.person_source_id, id: id },
                { highlight: false }
            );
        }
        this.highlightedPoint = null;
    }
}

class SmallMap extends BaseMap {
    constructor(props) {
        if (!props){            
            props = {
                container: 'small-map', // container ID
                style: 'mapbox://styles/asmevren/cle3olp6b005i01temkssjc8e', // style URL
                center: [35.237220, 38.932572], // starting position [lng, lat]
                zoom: 4, // starting zoom,
                attributionControl: false,
            };
        }
        super(props);      
          
        this.on('load', ()=>{
            const marker = new mapboxgl.Marker({
                // draggable: true
            }).setLngLat(this.getCenter()).addTo(this);
                this.on('move', function() {
                    marker.setLngLat(this.getCenter());
                });
        });        
    }
}


export { MainMap, SmallMap };