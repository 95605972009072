<template id="landing-component" class="component">

  <video class="background-video" aria-hidden="true" playsinline autoplay muted loop poster="@/assets/images/MHK_back02.jpg" id="hero-video"> 
    
    <source src="@/assets/videos/MHK_back02_720p.mp4" type="video/mp4">
  </video>
  
  <section id="landing-component" class="hero is-fullheight is-fullwidth">  
    <div class="hero-head">
      <div class="container">
        <div class="columns">
          <div class="column">
            <a  v-if="isShowMore" @click="isShowMore = false"><h2 class="is-pulled-left">GERİ</h2></a>
            <a v-if="!isShowMore" @click="isShowMore = true"><h2 class="is-pulled-right">Proje Hakkında</h2></a>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body is-fullheight">
      <Transition name="showmore-transition">
        <div v-show="!isShowMore" class="container has-text-centered main-content">
          <div class="columns is-centered" >
            <div class="column is-full-mobile is-three-quarter-tablet is-four-fifths-desktop is-three-fifths-fullhd" >
                <div>
                    <div class="text-content" >
                      <h1 id="title" class="title is-spaced is-uppercase">{{title}}</h1>
                      <p class="subtitle has-text-justified is-size-6-mobile is-size-6-tablet is-size-6-desktop" v-html="content"></p>
<div class="list-logo">
<a target="_blank" href="https://www.undp.org/" class="logo_undp"></a>
<a target="_blank" href="https://karma.ku.edu.tr/" class="logo_karma"></a>
<a target="_blank" href="https://about.meta.com/" class="logo_meta"></a>
</div>
                      <div  v-if="false" class="has-text-justified is-underlined has-text-weight-bold"><a @click="isShowMore = true"><p>Devamını okumak için tıkla</p></a></div>
                      <div  v-if="false" class="create-buttons columns is-mobile is-three-quarters-mobile">
                          <div class="column is-half-mobile is-one-third-tablet">
                            <a class="is-pulled-left" @click="$emit('onContributeLandmark')">
                              <h3 class="is-uppercase has-text-weight-bold">KENT BELLEĞİ OLUŞTUR</h3>
                              <div class="horizontal-divider"></div>
                              <p class="is-italic">Kentlerimizin kültürel mirasını saklamak için</p>
                              </a>
                          </div>
                          <div class="column is-offset-one-third-tablet is-half-mobile is-one-third-tablet">
                            <a class="is-pulled-right" @click="$emit('onContributePerson')">
                              <h3 class="is-uppercase has-text-weight-bold">KİŞİ BELLEĞİ OLUŞTUR</h3>
                              <div class="horizontal-divider"></div>
                              <p class="is-italic">Yakınlarımızı anmak ve anılarını saklamak için</p>
                            </a>
                          </div>
                        </div>
                    </div>
                
                  </div>
                </div>
            </div>
        </div>
      </Transition>

      <Transition name="showmore-transition">
        <div v-show="isShowMore" class="show-more container main-content">
          <div class="columns is-centered">
            <div class="column is-full-mobile is-three-quarter-tablet is-three-quarter-desktop is-three-fifths-fullhd">
                <div class="text-content">
<p>Medeniyetlerin Hafıza Kapısı, Anadolu'nun ve Türkiye'nin paha biçilmez maddi ve manevi mirasının korunması ve sergilenmesi amacıyla hazırlanmış bir arşiv projesidir. Günümüz dünyasında kültürel mirasımız doğal afetler, iklim değişikliği ve çatışmalar ile her zaman olduğundan daha büyük tehdit altındadır. UNDP Türkiye, Koç Üniversitesi KARMA XR Laboratuvarı, ve META'nın öncülük ettiği bu proje, yerel mirasları titizlikle arşivlemek ve görsel temsiller oluşturmak için kitle kaynaklı bir platform olmanın gücünden ve fotogrametri, 3D modelleme ve AR filtreleri dahil olmak üzere en ileri dijitalleştirme tekniklerinden yararlanıyor.</p>
<p>Projenin başlangıcı Şubat 2023'te Türkiye'de yaşanan depremle aynı zamana denk geliyor. Proje 2023 yılında üç farklı miras yapısı ile başladı. Her biri depremden doğrudan etkilenen şehirlerden seçilmiş olan Gaziantep Kalesi, Maraş Kapalıçarşı ve Hatay Arkeoloji Müzesi'ndeki Şuppililuima Heykeli.&nbsp;</p>
<p>Medeniyetlerin Hafıza Kapısının öncelikli amacı, kültürel mirasların korunması ve tanıtılmasına yönelik kolektif bir platform olarak hizmet vermek iken, aynı derecede önemli bir diğer amaç ise bu araçları birer eğitim kaynağı olarak halkın kullanımına sunmaktır. Bizi web sitemizden ve Instagram hesabımızdan takip ederek projenin geleceği hakkında bilgi sahibi olabilirsiniz.&nbsp;</p>
<p>Şimdi sizi haritayı ve miras sayfalarını derinlemesine incelemeye davet ediyoruz. Ayrıca kültürel mirasımız ve Anadolu dokuma tekniklerine odaklanan kapsayıcı ve etkileşimli bir deneyim için Instagram hesabımızdaki AR filtrelerini de deneyimleyebilirsiniz.&nbsp;</p>
<p></p>
<p><strong>Credits:&nbsp;</strong></p>
<p><strong>Proje ortakları /&nbsp;<br></strong>UNDP Türkiye<br>Koç Üniversitesi, KARMA XR Lab<br>Meta Türkiye</p>
<p><strong>Fikir ve Konsept geliştirme /&nbsp;<br></strong>Asım Evren Yantaç, İvon Bensason, Ali Vatansever; KU KARMA XR Lab<br>Azim Erdal, Aktif Toplum Derneği&nbsp;</p>
<p><strong>Kurumsal Kimlik Tasarımı / Corporate Identity Design<br></strong>Derin Kılıçoğlu, KU KARMA XR Lab<br>Websitesi Tasarımı / Website Design<br>Pelin Karaturhan, KU KARMA XR Lab<br>Websitesi Geliştirme / Website Development<br>Egemen Ertuğrul, KU KARMA XR Lab<br>Hüseyin Kuşcu, Kakare Interactive</p>
<p><strong>AR Filtre Tasarım ve Geliştirme, 3B modeller / AR Filtre Design and Development, 3D Models<br></strong>Okan Efe Öğretmen, KU KARMA XR Lab<br>Jaakko Saari, Sonagraf<br>Alp Esassolak</p>
<p><strong>İçerik ekibi / Content Team<br></strong>Cemre Sönmez, Vahide Sena Çoban, Sumru Deniz, Şevval Göç<br>Sosyal medya içerik yönetimi / Social Media Content Management<br>Cemre Sönmez, İpek Köprülül;&nbsp; Denden Creative Studio</p>
                </div>
            </div>
            </div>
        </div>
      </Transition>
  </div>
  <div class="hero-foot">
    <Transition name="arrowed-button-area">
      <!-- <div class="arrowed-button-area bottom" v-if="active"> -->
      <div class="arrowed-button-area bottom">
        <div class="rows has-text-centered">
            <div class="row">
              <div class="columns is-centered">
                <div @click="onArrowClicked" class="column is-one-third clickable">
                    <div id="return-to-landing-navbar-button" class="navbar-button">
                        <p class="is-uppercase">Haritayı ziyaret et</p>
                    </div>
                </div>
              </div>
            </div>
            <div @click="onArrowClicked" class="row clickable">
                <div class="arrowed-button">
                    <img src="@/assets/images/arrow.svg"/>
                </div>
            </div>
        </div>
      </div>
    </Transition>
  </div>
  </section>
</template>

<script>

import fitty from 'fitty';
export default {
  data() {
    return {
      isShowMore: false,
      isMobile: navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i),
    }
  },
  props: {
    active: Boolean,

    title: String,
    content: String,
    left_button: Object,
    right_button: Object,
  },
  methods: {
    onArrowClicked(){
      this.$emit('onArrowClicked');
    },
    updateFitty(){
      fitty.fitAll(); 
    }
  },
  mounted() {
    this.$emit('mounted');
    if(!this.isMobile){
          fitty('#title', {
        minSize: 16,
        maxSize: 72,
      });

      document.fonts.ready.then(this.updateFitty);
      window.addEventListener("resize", this.updateFitty);
    }
  }
}
</script>

<style lang="scss" scoped>
    // .column {
    //   margin: auto;
    // }
	#landing-component
	{
		background-image: url(@/assets/images/bg_clouds.jpg);
		background-position: 50% 50%;
		background-repeat:no-repeat;
		background-size:cover;
		
	}
	#title
	{
		color:transparent !important;
		background-image: url(@/assets/images/logo.png);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100px;
		margin-bottom: 50px;
		max-width:100%;
		@include until($desktop){
			margin-bottom:20px;
		}
	}
    .background-video {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      -o-filter: blur(3px);
      filter: blur(3px);
    }

    .title {
        // width: 100%;
        font-weight: 700;
        // font-size: 5rem;
        // line-height: 80.45px;
        white-space: nowrap;
        display: inline-block;
    }

    .subtitle {
        font-weight: 500;
        white-space: pre-line;
        font-stretch: normal;
        font-style: normal;

        @include until($desktop){
          font-size: 12px;
          line-height: 16px;
        }

        @include from($desktop){
          font-size: 18px;
          line-height: 30px;
        }
        
        letter-spacing: normal;
        text-align: justify;
        color: #323232 !important;
    }

    .main-content {
      position: absolute;
      left: 0px;
      right: 0px;
      max-height: 70vh;
      margin: auto;
      max-width: 100vw;
      overflow: hidden;

    }
    
    .text-content {
      overflow-y: auto;
      max-height: 70vh;
      padding: 15px;
		padding: 0% 0%;
      h2 {
        font-weight: 550;
      }
	p
	{
		font-size:0.9em !important; 
		line-height: 1.6em;

		
		@include until($desktop){
			
			padding:0% 5%;
		}
	}
    }


		.text-content p
		{
			margin-bottom:30px;
			@include from($desktop){
						
				
					}
		}
	.hero .hero-head h2
	{
		@include until($desktop){
			
			padding: 7% 5% 0% 5% !important;
		}
	}
    .text-content::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .text-content {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .create-buttons {
      // white-space: nowrap;
      // overflow: hidden;
      margin-top: 2vh;
    }
    .create-buttons .horizontal-divider{
      // white-space: nowrap;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .create-buttons h3 {
      // white-space: pre;
      @include until($desktop){
        font-size: 11px;
        font-weight: 800;
        text-decoration: underline;
      }
      @include from($desktop){
        font-size: 18px;
        font-weight: 700;
      }
    }

    .create-buttons p {
      // white-space: pre;
      @include until($desktop){
        font-size: 10px;
        font-weight: 500;
      }
      @include from($desktop){
        font-size: 11px;
        font-weight: 800;
      }
    }
	
    .create-buttons .horizontal-divider {
      @include until($desktop){
        border: none;
      }
      @include from($desktop){
        border: 1px solid #004777;
      }
    }

    .arrowed-button {
        padding-bottom: 1vh;
    }

	.list-logo
	{
		display:flex;
		align-items: center;
		justify-content: space-between;
		
		@include until($desktop){
			padding:0% 5%;
			margin-bottom:5em;
		}
	}
	.list-logo a
	{
		width:25%;
		height:80px;
		
		position:relative;
		display:flex;
		align-items: center;
		justify-content: center;
		
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		
		@include until($desktop){
			width:33% !important;
		}
	}
	.list-logo a:nth-child(2)
	{
		width:50%;
	}
	.list-logo a:after
	{
		content:'';
		width:2px;
		height:40px;
		background-color:#DBD0C3;
		position:absolute;
		right:0px;
	}
	.list-logo a:last-child:after
	{
		display:none;
	}
	.list-logo a.logo_karma
	{
		background-image: url(@/assets/images/logo_karma.png);
	}
	.list-logo a.logo_meta
	{
		background-image: url(@/assets/images/logo_meta.png);
	}
	.list-logo a.logo_undp
	{
		background-image: url(@/assets/images/logo_undp.png);
	}
    .hero {
      // @include until($desktop){
      //   min-height: 100dvh !important;
      // }
      
      .hero-head {
        z-index: 1;
        h1,h2,h3,p{
          padding: 2rem;
          font-weight: 500;
          text-transform: uppercase;
          color: #F9F7F0 !important;
        }
        .columns {
          margin-left: 0;
          margin-right: 0;
        }
        // @include until($desktop){
        //   min-height: 10dvh !important;
        // }
      }
      .hero-body {
 
p
{
color:#FFF !important;
text-align:center !important;
strong
{
	color:#FFF !important;
}
}
         @include until($desktop){
          padding:0% 0%;
		margin-top: -75px;
         }
      }
      .hero-foot {
        // @include until($desktop){
        //   min-height: 10dvh !important;
        // }
      }
    }

    .hero-foot
	{
		@include until($desktop){
			position: fixed;
    width: 100%;
    top: calc(100vh - 200px);
		}
	}
	
    .show-more{
      h2 {
        font-size: 24px;
      }
		padding:0% 12%;

p
{
color:#FFF !important;
text-align:left !important;
}
		@include until($desktop){
			
			padding:0% 5%;
		}
		

    }
	.arrowed-button-area
	{
		
		@include until($desktop){
			height:10rem;
		padding-top: 2em;
		}
	}
    // .arrowed-button-area {
    //     height: 6rem;
    //     width: 100%;
    //     position: relative;
    //     z-index: 1;
    //     background: linear-gradient(0deg, #3A7186 0%, rgba(254, 219, 199, 0) 100%);
    //     font-weight: 800;
    //     color: #F9F7F0;
    // }

    .showmore-transition-enter-active,
    .showmore-transition-leave-active {
      transition: opacity 0.5s ease-out;
    }

    .showmore-transition-enter-from,
    .showmore-transition-leave-to {
      opacity: 0;
    }

    .showmore-transition-enter-to,
    .showmore-transition-leave-from {
      transition-delay: 0.5s;
    }
</style>