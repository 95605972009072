import axios from 'axios';
import EntityLocation from './EntityLocation';

export default class HTTPRequester {
    static BASE_URL = "https://hafizakapisi.com.tr/data/";
    
    // get_request_endpoints = {

    // }

    static post_request_endpoints = {
        "get_list_dot": "get.list.dot.php",
        "get_list_city": "get.list.city.php",

        "get_single_entity": "get.single.entity.php",
        "get_list_district": "get.list.district.php",
        "get_list_neighborhood": "get.list.neighborhood.php",
        "set_single_memory": "set.single.memory.php",

        "upload_single_file": "upload.single.file.php",
    }

    static get_request_endpoint_url(request) {
        let endpoint = this.post_request_endpoints[request];
        if(!endpoint){
            throw new Error("Invalid endpoint.");
        }

        return new URL(endpoint, this.BASE_URL).href;
    }

    static request_preprocess_fn = {
        "set_single_memory": (arg) => {
            return {
                param: {
                    owner:{
                        name: arg.owner_name_surname,
                        surname: "",
                        phone: arg.owner_phone,
                        email: arg.owner_email
                    },
                    entity: // if new entity is created
                    {
                        type: 0, // arg.type // 0 if person, 1 if cultural heritage
                        imgId: 5, // arg.primary_image
                        coord: "22.4456, 65.4656677", // arg.coord
                        cityId: 34,
                        districtId: 5,
                        neighborhoodId: 10,
                        street: "cadde",
                        buildingNo: "bina no",
                        buildingName: "bina adı",
                        title: "", // if cultural heritage
                        name: arg.name_surname, // if person
                        surname: "", // if person
                        born: arg.birth_date, // if person format: 2023-02-14
                        died: arg.death_date, // if person format: 2023-02-14
                    },
                    entityId: 1, // if added to an existing entity
                    memory:
                    {
                        relationship: arg.owner_relation, // if person
                        info: arg.memory // memory text
                    },
                    gallery: [1,2,4] // arg.other_images // list of image id's
                }
            }
        }
    }

    static request_postprocess_fn = {
        "get_list_dot": (res) => {
            let data = res.data;
            let person_data = [];
            let place_data = [];

            data.forEach((element) => {
                element.id = parseInt(element.id);
                let coordinates = null;
                if(element.coord.length > 0){
                    coordinates = element.coord.split(',').map(s => parseFloat(s.trim()));
                    element.lat = coordinates[0];
                    element.lng = coordinates[1];
                }
                if(!coordinates)
                    return;
                    
                element.location = new EntityLocation({coordinates: coordinates.reverse(), address_string: ""})
                console.log("Sel2", element);

                element.type = parseInt(element.type);
                element.imgId = parseInt(element.imgId);
                element.title = element.title.trim();

                if(element.type === 0){
                    person_data.push(element);
                } else if(element.type === 1){
                    place_data.push(element);
                }
            });
            const person_data_modified = person_data.filter(element => !(element.title.length == 0 || element.coord == null));
            const place_data_modified = place_data.filter(element => !(element.title.length == 0 || element.coord == null));
            return [person_data_modified, place_data_modified];
        },
        "get_single_entity": (res) => {
            return [ res.data.entity, res.data.listMemory ];
        }
    }

    static async request(request, { param = null, callback = null }){
        const endpoint_url = this.get_request_endpoint_url(request);

        // const preprocess_fn = this.request_preprocess_fn[request];
        // param = preprocess_fn ? preprocess_fn(param) : param;

        let {data} = await axios({
            method: 'post',
            url: endpoint_url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param,
        });

        const postprocess_fn = this.request_postprocess_fn[request];
        data = postprocess_fn ? postprocess_fn(data) : data;
        
        callback?.(data);
    }
}